<template xmlns="http://www.w3.org/1999/html">
  <div>
      <div class="row mb-3">
          <div class="col-12">
              <div class="btn-group" role="group" aria-label="Basic radio toggle button group">
                  <input type="radio" class="btn-check" name="btnradio-faze-aktivni" id="btnradio-faze-aktivni" autocomplete="off" :checked="aktivniFaze == 'aktivni'">
                  <label @click="getPozadavky('aktivni')" class="btn btn-outline-secondary" for="btnradio-faze-aktivni">Aktivní</label>

                  <input type="radio" class="btn-check" name="btnradio-faze-pripraveno" id="btnradio-faze-pripraveno" autocomplete="off" :checked="aktivniFaze == 'pripraveno'">
                  <label @click="getPozadavky('pripraveno')" class="btn btn-outline-secondary" for="btnradio-faze-pripraveno">Připraveno k odběru</label>

                  <input type="radio" class="btn-check" name="btnradio-faze-pripraveno" id="btnradio-faze-predat-zamestnanci" autocomplete="off" :checked="aktivniFaze == 'predat_zamestnanci'">
                  <label @click="getPozadavky('predat_zamestnanci')" class="btn btn-outline-secondary" for="btnradio-faze-predat-zamestnanci">Vyzvednuto předat zaměstnanci</label>

                  <input type="radio" class="btn-check" name="btnradio-faze-predano-zamestnanci" id="btnradio-faze-predano-zamestnanci" autocomplete="off" :checked="aktivniFaze == 'predano_zamestnanci'">
                  <label  @click="getPozadavky('predano_zamestnanci')" class="btn btn-outline-secondary" for="btnradio-faze-predano-zamestnanci">Archiv</label>
              </div>
          </div>
      </div>
      <hr />
      <template v-if="aktivniFaze == 'predano_zamestnanci'">
          <div  class="row mb-3">
              <div class="col-6">
                  <div class="btn-group" role="group" aria-label="Basic radio toggle button group">

                      <template v-for="(rokItem, key) in roky">

                          <input type="radio" class="btn-check" name="btnradio-rok" :id="'btnradio-rok-' + key" autocomplete="off" :checked="rok == rokItem">
                          <label @click="filtrRok(rokItem)" class="btn btn-outline-primary bg-gradient" :for="'btnradio-rok-' + key">{{ rokItem }}</label>

                      </template>

                  </div>
              </div>
              <div class="col-6">

                <the-autocomplete
                    placeholder="Zadejte název vozíku / evidčíslo / výrobce / model"
                    adresa="get-vzv"
                    sablona="vzv-long"
                    hodnota="id_polozky"
                    value="vzv"
                    id="vzv"
                    parametry=""
                    @ac-vzv="FiltrEvidCislo"
                />

              </div>
          </div>
          <div  class="row mb-3">
              <div class="col-9" >
                  <div class="btn-group" role="group" aria-label="Basic radio toggle button group">

                      <template v-for="(mesicItem, key) in mesice">

                          <input type="radio" class="btn-check" name="btnradio-mesic" :id="'btnradio-mesic-' + key" autocomplete="off" :checked="mesic == (key + 1)">
                          <label @click="filtrMesic((key + 1))" class="btn btn-outline-primary bg-gradient" :for="'btnradio-mesic-' + key">{{ mesicItem }}</label>

                      </template>

                  </div>
              </div>
              <div class="col-3">

                  <div class="btn-group" role="group" aria-label="Basic radio toggle button group">

                      <input type="radio" class="btn-check" name="btnradio-uzivatel" id="btnradio-uzivatel-337" autocomplete="off" :checked="idUzivatele == 337">
                      <label @click="filtrUzivatele(337)" class="btn btn-outline-primary bg-gradient" for="btnradio-uzivatel-337">Dostál</label>

                      <input type="radio" class="btn-check" name="btnradio-uzivatel" id="btnradio-uzivatel-477" autocomplete="off" :checked="idUzivatele == 477">
                      <label @click="filtrUzivatele(477)" class="btn btn-outline-primary bg-gradient" for="btnradio-uzivatel-477">Daniel</label>

                      <input type="radio" class="btn-check" name="btnradio-uzivatel" id="btnradio-uzivatel-312" autocomplete="off" :checked="idUzivatele == 312">
                      <label @click="filtrUzivatele(312)" class="btn btn-outline-primary bg-gradient" for="btnradio-uzivatel-312">Koubek</label>

                      <input type="radio" class="btn-check" name="btnradio-uzivatel" id="btnradio-uzivatel-17" autocomplete="off" :checked="idUzivatele == 17">
                      <label @click="filtrUzivatele(17)" class="btn btn-outline-primary bg-gradient" for="btnradio-uzivatel-17">VM</label>

                  </div>

              </div>
          </div>
          <hr />
      </template>

      <div class="row">
          <div class="table-responsive">
            <table class="table table-bordered">
              <thead class="thead-light table-bordered">
                  <tr>
                      <th class="text-center align-middle">Datum</th>
                      <th class="text-center align-middle">Vytvořil požadavek</th>
                      <th class="text-center align-middle">Vozík</th>
                      <th class="text-center align-middle">Pro zaměstnance</th>
                      <th class="text-center align-middle">Počet položek</th>
                      <th v-if="aktivniFaze == 'pripraveno'" class="text-center align-middle">Počet připravených<br />položek</th>
                      <th class="text-center align-middle">Typ odberu</th>
                      <th class="text-center align-middle">Faze požadavku</th>
                      <th class="text-center align-middle">Detail</th>
                      <th class="text-center align-middle">Odstranit</th>
                  </tr>
              </thead>
              <tbody class="table-bordered" :key="'pozadavkyKey-' + pozadavkyKey">
              <template v-for="pozadavek in pozadavky">
                  <tr>
                      <td class="text-center align-middle" v-html="pozadavek.datum_vytvoreni_format">

                      </td>
                      <td class="text-center align-middle">
                          {{ pozadavek.tvurce }}
                      </td>
                      <td class="text-left align-middle">
                          {{ pozadavek.evidcislo}} {{ pozadavek.vyrobce }} {{ pozadavek.model}}
                      </td>
                      <td class="text-center align-middle">
                          {{ pozadavek.odberatel }}
                      </td>
                      <td class="text-center align-middle">
                          {{ pozadavek.pocet_polozek }}
                      </td>
                      <td v-if="aktivniFaze == 'pripraveno'" class="text-center align-middle">
                          {{ pozadavek.pocet_polozek_pripraveno }}
                      </td>
                      <td class="text-center align-middle">
                          {{ pozadavek.typ_odberu }}
                      </td>
                      <td class="text-center align-middle">
                          {{ pozadavek.faze_pozadavku }}
                      </td>
                      <td class="text-center">
                          <button @click="detailPozadavku(pozadavek.id_pozadavku)"  type="button" class="btn btn-primary">
                              Detail položek
                          </button>
                      </td>
                      <td class="text-center">
                          <button @click="odstranitPozadavek(pozadavek.id_pozadavku)" class="btn btn-danger">
                              Odstranit
                          </button>
                          <the-modal-dialog
                              id="modalOdstranitPozadavek"
                              title="Odstranit daný požadavek"
                              size="extra-large"
                              buttons="odstranit,storno">

                            Opravdu chcete odstranit daný požadavek
                          </the-modal-dialog>
                      </td>
                  </tr>
                  <tr v-if="detailPozadavkuOpen[pozadavek.id_pozadavku] == true">
                      <td colspan="11">

                          <div class="table-responsive m-3">
                              <table class="table table-bordered">
                                  <tbody>
                                      <tr>
                                          <th style="width : 100px">
                                              Poznámka
                                          </th>
                                          <td class="text-wrap">
                                              {{ pozadavek.poznamka }}
                                          </td>
                                      </tr>
                                      <tr v-if="pozadavek.bez_polozek == 1">
                                          <th style="width : 100px">
                                              Předáno zaměstnanci
                                          </th>
                                          <td class="text-wrap">
                                              <the-toggle @checkbox="checkZmenaFazePozadavek($event, pozadavek.id_pozadavku)" :set-checked="nastaveniZmenaFazePozadavek(pozadavek.faze_pozadavku_enum)" value="1"></the-toggle>
                                          </td>
                                      </tr>
                                  </tbody>
                              </table>
                          </div>

                          <div class="table-responsive">
                            <table class="table table-bordered">
                              <thead class="thead-light table-bordered">
                                  <th class="text-center align-middle background-dark">Foto</th>
                                  <th class="text-center align-middle background-dark">Skladové číslo</th>
                                  <th class="text-center align-middle background-dark">Název položky</th>
                                  <th class="text-center align-middle background-dark">Počet MJ</th>
                                  <th v-if="aktivniFaze == 'pripraveno'" class="text-center align-middle background-dark">Připraveno</th>
                                  <th class="text-center align-middle background-dark">Použité</th>
                                  <th class="text-center align-middle background-dark">Typ odběru</th>
                                  <th class="text-center align-middle background-dark">Fáze položky</th>
                                  <th class="text-center align-middle background-dark">Předat<br />zaměstnanci</th>
                                  <th class="text-center align-middle background-dark">Odstranit</th>
                              </thead>
                              <tbody class="table-bordered" :key="'pozadavkyPolozkyKey-' + pozadavkyPolozkyKey">
                                  <tr v-for="(polozka, key) in pozadavkyPolozky" :key="polozka.id_polozky">
                                        <td class="text-center" style="width : 125px">
                                            <img :src="$adminUrl + polozka.foto_cesta" class="img img-fluid" width="75%" />
                                        </td>
                                        <td class="text-center align-middle">
                                            <detail-polozky
                                                :id="'detailPolozky-' + polozka.id_polozky"
                                                :id-polozky="polozka.id_polozky"
                                                :skladove-cislo="polozka.skladove_cislo" >

                                            </detail-polozky>
                                        </td>
                                        <td class="align-middle">{{ polozka.nazev_polozky }}</td>
                                        <td v-if="polozka.nezadany_pocet == 1" class="text-center align-middle">
                                           <div class="row">
                                              <div class="col">
                                                <input type="text" class="form-control" v-model="polozka.pocet_mj" />
                                              </div>
                                              <div class="col align-middle text-left">
                                                  <span class="badge bg-success align-middle text-left">{{ polozka.merna_jednotka }}</span>
                                              </div>
                                           </div>
                                        </td>
                                        <td v-else class="text-center align-middle">
                                          {{ polozka.pocet_mj | numberFormat}} {{ polozka.merna_jednotka }}
                                        </td>
                                        <td v-if="aktivniFaze == 'pripraveno'" class="text-center align-middle">
                                            {{ polozka.pripraveno_mj }}
                                        </td>
                                        <td class="text-center align-middle" v-bind:class="{pouzita: polozka.pouzita == 1}">
                                            <span v-if="polozka.pouzita == 1">ANO</span>
                                            <span v-else>NE</span>
                                        </td>
                                        <td class="text-center align-middle">
                                          <template v-if="polozka.typ_odberu_enum == 'sklad-pozdeji'">
                                              <button @click="vyskladnitNyni(polozka.id_pozadavku_polozky)" type="button" class="btn btn-success" >Vyskladnit nyní</button>
                                          </template>
                                          <template v-else>
                                              {{ polozka.typ_odberu }}
                                          </template>
                                        </td>
                                        <td class="text-center align-middle">{{ polozka.faze_polozky }}</td>
                                        <td class="text-center align-middle">
                                            <the-toggle v-if="polozka.faze_polozky != 'Předáno zaměstnanci'"  @checkbox="checkZmenaFaze($event, key, polozka.id_pozadavku_polozky)" value="1"></the-toggle>
                                        </td>
                                      <td class="text-center align-middle">
                                          <button @click="odstranitPozadavekPolozku(polozka.id_pozadavku_polozky)" class="btn btn-danger">
                                            Odstranit
                                          </button>
                                          <the-modal-dialog
                                              :id="'modalOdstranitPozadavekPolozku'"
                                              title="Odstranit daný požadavek"
                                              size="extra-large"
                                              buttons="odstranit,storno">

                                                Opravdu chcete odstranit danou položku.
                                          </the-modal-dialog>
                                        </td>
                                  </tr>
                              </tbody>
                            </table>
                          </div>
                      </td>
                  </tr>
              </template>
              </tbody>
            </table>
          </div>
      </div>

  </div>
</template>

<script>

import TheAutocomplete from "@/components/form/TheAutocomplete";
import TheModalDialog from "@/components/TheModalDialog";
import TheToggle from "@/components/form/TheToggle";
import DetailPolozky from "@/views/DetailPolozky";
import axiosApiPost from "../axiosApiMethod";
import {bus} from "../main";

export default {
    name: "Pozadavky",
    components: {TheModalDialog, TheToggle, DetailPolozky, TheAutocomplete},
    props: {
      aktivniFazeVstup : String,
    },
    data () {
      return {

        aktivniFaze : String,

          // Filtr
            mesic : 0,
            rok : 0,
            idUzivatele : 0,
            idPolozky : 0,

          mesice : [],
          roky : [],

          pozadavky : [],
          pozadavkyKey : 0,

          pozadavkyPolozky : [],
          pozadavkyPolozkyKey : 0,

          detailPozadavkuOpen: {},
          odstraneniIdPozadavku: 0,
          odstraneniIdPozadavkuPolozky: 0,
      }
    },
    created() {

        this.aktivniFaze = this.aktivniFazeVstup;

        this.fillMesice();
        this.fillRoky();

        this.getPozadavky(this.aktivniFaze);

        //
        // Odstraneni pozadavku
            bus.$on('modalDialogSave-' + 'modalOdstranitPozadavek', (result) =>
            {
                if(result == true)
                {
                    axiosApiPost('delete-pozadavek', {id_pozadavku: this.odstraneniIdPozadavku}, 'data').then( result => {

                        let tempThis = this;
                        _.remove(this.pozadavky, function(n) {

                            return n.id_pozadavku == tempThis.odstraneniIdPozadavku;
                        });

                        this.odstraneniIdPozadavku = 0;
                        this.pozadavkyKey++;

                    });
                }
            });

        //
        // Odstraneni polozky
            bus.$on('modalDialogSave-' + 'modalOdstranitPozadavekPolozku', (result) =>
            {
                if(result == true)
                {
                    axiosApiPost('delete-pozadavek-polozka', {id_pozadavku_polozky: this.odstraneniIdPozadavkuPolozky}, 'data').then( result => {

                        let tempThis = this;
                        _.remove(this.pozadavkyPolozky, function(n) {

                          return n.id_pozadavku_polozky == tempThis.odstraneniIdPozadavkuPolozky;
                        });

                        this.odstraneniIdPozadavkuPolozky = 0;
                        this.pozadavkyPolozkyKey++;

                    });
                }
            });
    },
    methods: {
        getPozadavky(fazePolozky)
        {
            this.aktivniFaze = fazePolozky;

            if(this.aktivniFaze == 'predano_zamestnanci' && this.mesic == 0 && this.rok == 0)
            {
                const datumNow = new Date();
                this.mesic = datumNow.getMonth() + 1;
                this.rok = datumNow.getFullYear();
            }

            if(this.idUzivatele == 0)
            {
                this.idUzivatele = localStorage.getItem('userId');
            }

            var parametry = {
              faze : fazePolozky,
            };

            if(this.mesic > 0)
            {
                parametry.mesic = this.mesic;
            }
            if(this.rok > 0)
            {
                parametry.rok = this.rok;
            }
            if(this.idUzivatele > 0)
            {
                parametry.id_uzivatele = this.idUzivatele;
            }
            if(this.idPolozky > 0)
            {
                parametry.id_polozky_vzv = this.idPolozky;
            }

          if(this.aktivniFaze == 'predano_zamestnanci')
          {
              parametry['order_by'] = 'id_pozadavku';
              parametry['sort'] = 'desc';
          }

            axiosApiPost('get-pozadavky', parametry, 'data').then( result => {
                this.pozadavky = result;

                $.each(this.pozadavky, (key, value) => {
                    this.$set(this.detailPozadavkuOpen, value['id_pozadavku'], false);
                })
            });
        },

        detailPozadavku(idPozadavku)
        {
            if(this.detailPozadavkuOpen[idPozadavku] == false)
            {
                $.each(this.pozadavky, (key, value) => {
                  this.$set(this.detailPozadavkuOpen, value['id_pozadavku'], false);
                });

                this.$set(this.detailPozadavkuOpen, idPozadavku, true);

                if(this.aktivniFaze == 'pripraveno')
                {
                    var parametry = {
                      id_pozadavku: idPozadavku,
                      pripraveno_k_odberu : 1,
                    };
                }
                else
                {
                    var parametry = {
                      id_pozadavku: idPozadavku,
                    };
                }

                axiosApiPost('get-pozadavky-polozky', parametry, 'data').then( result => {
                    this.pozadavkyPolozky = result;
                });

            }
            else
            {
                this.$set(this.detailPozadavkuOpen, idPozadavku, false);
            }
        },
        odstranitPozadavek(idPozadavku)
        {
            bus.$emit('modalOdstranitPozadavek', {});
            this.odstraneniIdPozadavku = idPozadavku;
        },
        odstranitPozadavekPolozku(idPozadavkuPolozky)
        {
            bus.$emit('modalOdstranitPozadavekPolozku', {});
            this.odstraneniIdPozadavkuPolozky = idPozadavkuPolozky;
        },


        vyskladnitNyni(idPozadavkuPolozky)
        {
            axiosApiPost('uloz-typ-odberu', { 'typ_odberu' : 'sklad', 'id_pozadavku_polozky' : idPozadavkuPolozky}, 'status').then( result => {

                _.remove(this.pozadavkyPolozky, function(n) {
                    return n.id_pozadavku_polozky == idPozadavkuPolozky;
                })

                this.pozadavkyPolozkyKey++;
            });
        },

        //
        // Toggle
          checkZmenaFaze(event, key, idPozadavkuPolozky)
          {
              let faze = '';

              if(event == true)
              {
                  this.pozadavkyPolozky[key].faze_polozky = 'predano_zamestnanci';
                  faze = 'predano_zamestnanci';
              }
              else
              {
                  this.pozadavkyPolozky[key].faze_polozky = 'aktivni';
                  faze = 'aktivni';
              }

              axiosApiPost('uloz-fazi-polozky', { 'faze_polozky' : faze, 'id_pozadavku_polozky' : idPozadavkuPolozky}, 'status').then( result => {

              });
          },
          checkZmenaFazePozadavek(event, idPozadavku)
          {
              let faze = '';

              if(event == true)
              {
                  faze = 'predano_zamestnanci';
              }
              else
              {
                  faze = 'aktivni';
              }

              axiosApiPost('uloz-fazi-pozadavku', { 'faze_pozadavku' : faze, 'id_pozadavku' : idPozadavku}, 'status').then( result => {
                  this.getPozadavky(this.aktivniFaze);
              });
          },
          nastaveniZmenaFazePozadavek(fazePozadavkuEnum)
          {
              if(fazePozadavkuEnum == 'predano_zamestnanci' && this.aktivniFaze == 'predano_zamestnanci')
              {
                  return true;
              }
              else
              {
                  return false;
              }
          },

       // Filtr
          filtrMesic(mesic)
          {
              this.mesic = mesic;
              this.getPozadavky('predano_zamestnanci');
          },
          filtrRok(rok)
          {
              this.rok = rok;
              this.getPozadavky('predano_zamestnanci');
          },
          filtrUzivatele(idUzivatele)
          {
              this.idUzivatele = idUzivatele;
              this.getPozadavky('predano_zamestnanci')
          },
          FiltrEvidCislo(idPolozky)
          {
              this.idPolozky = idPolozky;
              this.getPozadavky('predano_zamestnanci')
          },

          fillMesice()
          {
              this.mesice = [
                'Leden',
                'Únor',
                'Březen',
                'Duben',
                'Květen',
                'Červen',
                'Červenec',
                'Srpen',
                'Září',
                'Říjen',
                'Listopad',
                'Prosinec',
              ];
          },
          fillRoky()
          {
              let d = new Date();
              let prvniRok = 2021;
              let nyniRok = d.getFullYear();

              for(let rok = prvniRok; rok <= nyniRok; rok++)
              {
                  this.roky.push(rok);
              }

          },

    },
    filters: {
        numberFormat(value) {
            return _.replace(value, '.', ',');
        }
    },
    metaInfo() {
      return {
        title: "Požadavky intralogistika",
        meta: [
          { name: 'description', content:  'Aplikace pro intralogistiky'},
          { property: 'og:title', content: "Požadavky intralogistika"},
          { property: 'og:site_name', content: 'Požadavky intralogistika'},
          { property: 'og:type', content: 'website'},
          { name: 'robots', content: 'noindex,nofollow'}
        ]
      }
    }
}
</script>

<style scoped>

.pouzita {
  background-color: #00B5B8;
}

</style>